import { Injectable } from '@angular/core';
import { AccountStore } from './account.store';
import { AccountQuery } from '@core/store/account/account.query';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AccountService {
  constructor(
    private store: AccountStore,
    private query: AccountQuery,
    private router: Router
  ) {
  }

  public setToken(token: string): void {
    this.store.update({ token });
  }

  public setGraphToken(graphToken: string): void {
    this.store.update({ graphToken: graphToken || '' });
  }

  public setError(error: boolean): void {
    this.store.update({ error });
  }

  public setUser(user: any): void {
    this.store.update({ user });
  }
}
