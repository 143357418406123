import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { AccountQuery } from '@core/store/account/account.query';
import { snapshot } from '@shared/utils/snapshot.util';
import { IIdentityUser } from '@models/data.models';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard implements CanActivate, CanActivateChild {
  constructor(private accountQuery: AccountQuery, private router: Router) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkPermissions(route);
  }

  public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkPermissions(childRoute);
  }

  private checkPermissions(route: ActivatedRouteSnapshot): boolean {
    const user: IIdentityUser = snapshot(this.accountQuery.user);

    if (!route.data.roles.includes(user?.userRelation?.userRole)) {
      this.router.navigateByUrl('/access-denied');
      return false;
    }

    return true;
  }

}
