import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Size } from '@usitsdasdesign/dds-ng/shared';

@Component({
  selector: 'app-common-modal-container',
  templateUrl: './common-modal-container.component.html',
  styleUrls: ['./common-modal-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonModalContainerComponent {
  @Input() size: Size | string = Size.md;
  @Input() title: string;
  @Input() customClass = '';
  @Input() borders = true;
  @Input() mobileFullHeight = false;

  @Output() onClose = new EventEmitter<void>();

  close(): void {
    this.onClose.emit();
  }
}
