import { UrlSegment } from '@angular/router';
import { availableTickets, TicketType } from '@core/constants/ticket-type.const';

export class CreateTicketMatcher {
  static match(url: UrlSegment[]): { consumed: UrlSegment[]; posParams: Record<string, UrlSegment> } {
    const length = url.length;
    const [name, type] = url;
    const validTicket = availableTickets.includes(type?.path as TicketType);

    if (name?.path !== 'create-ticket' || !type?.path || !validTicket || url?.length !== 2) {
      return null;
    }
    const posParams: Record<string, UrlSegment> = {};
    // @ts-ignore
    posParams.type = new UrlSegment(String(type.path), {});

    return {
      consumed: url,
      posParams
    };
  }
}
