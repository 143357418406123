import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private prefix = 'QR_';

  public get<T>(key: string): T {
    return JSON.parse(localStorage.getItem(`${this.prefix}${key}`) || null);
  }

  public set<T>(key: string, payload: T): void {
    localStorage.setItem(`${this.prefix}${key}`, JSON.stringify(payload));
  }

  public remove(key: string): void {
    localStorage.removeItem(`${this.prefix}${key}`);
  }

  public clear(): void {
    localStorage.clear();
  }
}
