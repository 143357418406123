import { APP_INITIALIZER } from '@angular/core';
import { environment } from '@environments/environment';

function analyticsInitializer() {
  const src: string = environment.analyticsScript;
  const script: HTMLScriptElement = document.createElement('script');

  script.setAttribute('src', src);
  script.async = true;
  document.head.appendChild(script);
}

export const analyticsInitializerProvider = {
  provide: APP_INITIALIZER,
  useValue: analyticsInitializer,
  multi: true,
};
