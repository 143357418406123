import { Injectable } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  private windowResize = fromEvent(window, 'resize', { passive: true })
    .pipe(
      debounceTime(100),
      startWith(window.innerWidth),
      shareReplay(1),
    );

  get windowWidth(): number {   
    return window.innerWidth;
  }

  public windowScroll = fromEvent(window, 'scroll', { passive: true })
    .pipe(
      startWith(null),
      map(() => window.pageYOffset),
      distinctUntilChanged(),
      shareReplay(1),
    );

  public isMobile = this.windowResize.pipe(
    map(() => this.windowWidth <= 767),
    distinctUntilChanged(),
    shareReplay(1),
  );

  public isTablet = this.windowResize.pipe(
    map(() => this.windowWidth >= 767 && this.windowWidth <= 1229),
    distinctUntilChanged(),
    shareReplay(1),
  );

  public isDesktop = this.windowResize.pipe(
    map(() => this.windowWidth >= 1230),
    distinctUntilChanged(),
    shareReplay(1),
  );
}
