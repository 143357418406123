import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationComponent } from './confirmation.component';
import { ButtonModule } from '@usitsdasdesign/dds-ng/button';
import { ModalModule } from '@usitsdasdesign/dds-ng/modal';
import { ResizeHandlerModule } from '@usitsdasdesign/dds-ng/shared/resize-handler';
import { CommonModalContainerModule } from '@shared/components/common-modal-container/common-modal-container.module';

@NgModule({
  declarations: [
    ConfirmationComponent
  ],
  imports: [
    CommonModule,
    ButtonModule,
    ModalModule,
    ResizeHandlerModule,
    CommonModalContainerModule
  ],
  exports: [
    ConfirmationComponent
  ]
})
export class ConfirmationModule {
}
