import { Injectable } from '@angular/core';
import { ModalService as DDSModalService } from '@usitsdasdesign/dds-ng/modal';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

type ModalData = any | { [key: string]: any; isCanceled?: boolean; error?: any };

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private ddsModalService: DDSModalService) {
  }

  public open(component: any, inputs?: { [key: string]: any }, enableEscForClosing?: boolean): Observable<any> {
    return this.ddsModalService
               .open(component, inputs, enableEscForClosing)
               .onClosed()
               .pipe(filter(data => !data?.isCanceled));
  }

  public close(data?: any): void {
    this.ddsModalService.close(data);
  }

  public cancel(error?: unknown): void {
    this.ddsModalService.close({ error, isCanceled: true });
  }
}
