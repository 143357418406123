<app-common-modal-container [title]="title" (onClose)="cancel()">
  <ng-container body>
    <div class="dds-body">{{ body }}</div>
  </ng-container>

  <ng-container footer>
    <div class="dds-modal__footer-content dds-modal__footer-content_right">
      <dds-button
        class="close-button"
        kind="secondary"
        width="fixed"
        type="button"
        (clicked)="cancel()"
      >
        <span>{{ cancelText }}</span>
      </dds-button>
      <dds-button
        type="button"
        width="fixed"
        [theme]="confirmTheme"
        (clicked)="close()"
      >
        <span>{{ confirmText }}</span>
      </dds-button>
    </div>
  </ng-container>
</app-common-modal-container>
<div
  class="dds-modal-overlay"
  (click)="cancel()"
></div>
