import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { FormChangesService } from '@core/services/form-changes.service';
import { ModalService } from '@shared/services/app-modal-service.service';
import { filter, mapTo, switchMap, tap } from 'rxjs/operators';
import { ConfirmationComponent } from '@shared/components/modals/confirmation/confirmation.component';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class FormChangesGuard implements CanDeactivate<Observable<boolean>> {
  constructor(private formChangesService: FormChangesService, private modalService: ModalService,  private route: ActivatedRoute) {
  }

  public canDeactivate(): Observable<boolean> {
    return this.formChangesService.dirty.pipe(
      switchMap(dirty => {
        if (!dirty) {
          return of(true);
        }
        const from = this.route.snapshot.queryParams['from'];
        if(!(from && from === 'requests')) 
        {
        return this.modalService.open(ConfirmationComponent).pipe(
          filter(res => res !== null),
          tap(() => {
            this.formChangesService.setDirty(false);
          }),
          mapTo(true)
        );
        }
        else{
          return of(true);
        }
      })
    );
  }

}
