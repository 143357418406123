import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { AccountQuery } from '@core/store/account/account.query';
import { DeviceService } from '@core/services/device.service';
import { SelectSimplifier } from '@shared/utils/select-simplifier.util';
import { untilDestroyed } from '@ngneat/until-destroy';
import { IIdentityUser } from '@models/data.models';
import { NavigationEnd, Router } from '@angular/router';
import { filter, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHeaderComponent implements OnInit {
  public user: IIdentityUser;
  public initials: string;
  public isSuperAdmin = false;
  public isSuperVisor = false;
  public isGroupManagement = false;
  public isMobile = false;
  public currentRoute: string;

  constructor(
    private accountQuery: AccountQuery,
    private authService: AuthService,
    private deviceService: DeviceService,
    private cd: ChangeDetectorRef,
    private router: Router,
  ) {
  }

  public ngOnInit(): void {
    new SelectSimplifier(this, this.cd)
      .selectKey(this.deviceService.isMobile, 'isMobile')
      .selectKey(this.accountQuery.user, 'user')
      .selectKey(this.accountQuery.initials, 'initials')
      .selectKey(this.accountQuery.isGroupManagement, 'isGroupManagement')
      .selectKey(this.accountQuery.isSuperAdmin, 'isSuperAdmin')
      .selectKey(this.accountQuery.isSuperVisor, 'isSuperVisor')
      .addObservable(this.watchCurrentUrl())
      .watchUntil(untilDestroyed(this))
      .subscribe();
  }

  public onLogout(): void {
    this.authService.doLogout();
  }

  public openCookies(): void {
    window.open('https://cookienotice.deloitte.com', '_blank');
  }

  public onCookieSettings(): void {
    // @ts-ignore
    window['Optanon']?.ToggleInfoDisplay();
  }

  private watchCurrentUrl(): Observable<unknown> {
    return this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      // @ts-ignore
      tap(event => this.currentRoute = event.urlAfterRedirects),
    )
  }
}
