import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { StorageService } from '@core/services/storage.service';
import { environment } from '@environments/environment';

export interface AliasState {
  current: string;
  list: string[];
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'alias' })
export class AliasStore extends Store<AliasState> {
  constructor(storageService: StorageService) {
    super({
      current: !environment.enableAlias ? '' : storageService.get<string>('alias') || '',
      list: !environment.enableAlias ? [] : storageService.get<string[]>('aliases') || [],
    });
  }
}
