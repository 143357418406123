import { Injectable, Provider } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AccountQuery } from '@core/store/account/account.query';
import { Observable } from 'rxjs';
import { AuthService } from '@core/services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  constructor(private accountQuery: AccountQuery, private authService: AuthService) {
  }

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.authService.accessToken;
    const ignoreBearer = req.headers.has('ignoreBearer');
    const graphRequest = req.headers.has('graphRequest');

    if (!token || ignoreBearer) {
      return next.handle(req.clone({ headers: req.headers.delete('ignoreBearer') }));
    }
    const headers = req.headers.set('Authorization', `Bearer ${token}`);
    return next.handle(req.clone({ headers }));
  }
}

export const authInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true,
};
