import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { AliasState, AliasStore } from './alias.store';

@Injectable({ providedIn: 'root' })
export class AliasQuery extends Query<AliasState> {
  public current = this.select('current');
  public list = this.select('list');

  constructor(store: AliasStore) {
    super(store);
  }
}
