import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'buttonActive'
})
export class ButtonActivePipe implements PipeTransform {
  public transform(value: string, match: string, isRegex = ''): string {
    if (isRegex) {
      let regexValue = new RegExp(isRegex);
      return regexValue.test(match) || value.includes(match) ? 'green' : 'dark';
    }
    return value.includes(match) ? 'green' : 'dark';
  }
}
