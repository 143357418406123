import { Injectable, Provider } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, first, retry, switchMap } from 'rxjs/operators';
import { AccountService } from '@core/store/account/account.service';
import { AuthService } from '@core/services/auth.service';
import { fromPromise } from 'rxjs/internal-compatibility';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private accountService: AccountService, private authService: AuthService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403) {
          // @ts-ignore
          return fromPromise(this.authService.authorizeUser()).pipe(
            first(),
            switchMap(() => {
              const token = this.authService.accessToken;
              return next.handle(this.applyCredentials(request, token)).pipe(retry(3));
            })
          );
        }

        return throwError(error);
      })
    );
  }

  private applyCredentials(req: HttpRequest<any>, token: string): HttpRequest<any> {
    this.accountService.setToken(token);

    return req.clone({
      headers: req.headers.set('Authorization', `Bearer ${token}`),
    });
  }
}

export const httpErrorInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: HttpErrorInterceptor,
  multi: true,
};
