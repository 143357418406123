import { Observable } from 'rxjs';

export function snapshot<T>(src: Observable<T>): T {
  let res = null;

  const subsr = src.subscribe((data) => (res = data));
  subsr.unsubscribe();

  return res as T;
}
