import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

declare global {
  interface Window {
    OneTrust: {
      Init: () => void;
      LoadBanner: () => void;
      ToggleInfoDisplay: () => void;
    };
  }
}

@Injectable({
  providedIn: 'root'
})
export class CookieService {
  public init(): void {
    var openComment = document.createComment('OneTrust Cookies Consent Notice start for officeexperiencesupport.deloitte.com');
    var closeComment = document.createComment('OneTrust Cookies Consent Notice end for officeexperiencesupport.deloitte.com');
    var cookiesScript_1 = document.createElement('script');
    cookiesScript_1.type = 'text/javascript';
    cookiesScript_1.defer = true;
    cookiesScript_1.charset = 'UTF-8';
    cookiesScript_1.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    cookiesScript_1.dataset.domainScript = environment.productionEnv
      ? 'ac45c490-fc06-45bf-b0a7-fe28a49f87d5'
      : 'ac45c490-fc06-45bf-b0a7-fe28a49f87d5-test';
    var cookiesScript_2 = document.createElement('script');
    cookiesScript_2.type = 'text/javascript';
    cookiesScript_2.defer = true;
    cookiesScript_2.innerHTML = 'function OptanonWrapper() { }';
    document.head.appendChild(openComment);
    document.head.appendChild(cookiesScript_1);
    document.head.appendChild(cookiesScript_2);
    document.head.appendChild(closeComment);
  }

  public reloadOTBanner(): void {
    const otConsentSdk = document.getElementById('onetrust-consent-sdk');

    if (otConsentSdk) {
      otConsentSdk.remove();
    }

    if (!!window.OneTrust) {
      window.OneTrust.Init();

      setTimeout(() => {
        window.OneTrust.LoadBanner();
        const toggleDisplay = document.getElementsByClassName('ot-sdk-show-settings');

        for (let i = 0; i < toggleDisplay.length; i++) {
          // @ts-ignore
          toggleDisplay[i].onclick = (event) => {
            event.stopImmediatePropagation();
            window.OneTrust.ToggleInfoDisplay();
          }
        }
      }, 500)
    }
  }
}
