import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ModalService } from '@shared/services/app-modal-service.service';
import { Themes } from '@usitsdasdesign/dds-ng/shared';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationComponent {
  @Input() title = 'Unsaved changes';
  @Input() body = 'Unsaved changes will be lost. Do you want to continue?';
  @Input() cancelText = 'Cancel';
  @Input() confirmText = 'Yes, continue';
  @Input() confirmTheme = Themes.danger;

  constructor(private modalService: ModalService) {
  }

  public close() {
    this.modalService.close();
  }

  public cancel() {
    this.modalService.cancel();
  }
}
