import { Injectable } from '@angular/core';
import { PageInfo, TrackActions } from '@shared/models/analytics.models';

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  public trackClick(description: string): void {
    this.pageInfo.actiontrack = description;
    this.track('onclicks');
  }

  public trackPageInfo(url: string, username: string): void {
    if (this.pageInfo) {
      this.updatePageInfo(url, username);
    } else {
      this.pageInfo = {
        pageName: url,
        userID: username
      };
    }

    this.track('pageView');
  }

  private updatePageInfo(url: string, username: string): void {
    this.pageInfo.pageName = url;
    this.pageInfo.userID = username;
  }

  private get pageInfo(): PageInfo {
    return (window as Window).analyticsDataLayer?.pageInfo;
  }

  private set pageInfo(pageInfo: PageInfo) {
    (window as Window).analyticsDataLayer = { pageInfo };
  }

  private track(action: TrackActions): void {
    (window as Window)._satellite?.track(action);
  }
}
