import { Injectable, Provider } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AccountQuery } from '@core/store/account/account.query';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class ApiUrlInterceptor implements HttpInterceptor {
  constructor(private accountQuery: AccountQuery) {
  }

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const url = req.url.replace('//', '/');
    return next.handle(req.clone({ url: `${environment.apiBaseUrl}${url}` }));
  }
}

export const apiUrlInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ApiUrlInterceptor,
  multi: true,
};
