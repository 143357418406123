import { Routes } from '@angular/router';
import { CreateTicketMatcher } from '@core/services/create-ticket.matcher';
import { PermissionGuard } from '@core/guards/permission.guard';
import * as DataEnums from '@models/data.enums';
import { FormChangesGuard } from '@core/guards/form-changes.guard';

export const appRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
      },
      {
        matcher: CreateTicketMatcher.match,
        loadChildren: () => import('./pages/create-ticket/create-ticket.module').then(m => m.CreateTicketModule),
      },
      {
        path: 'create-ticket',
        loadChildren: () => import('./pages/create/create.module').then(m => m.CreateModule),
        canActivate: [PermissionGuard],
        canDeactivate: [FormChangesGuard],
        data: {
          roles: [DataEnums.UserRole.TeamAdmin, DataEnums.UserRole.SuperVisor],
        },
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'my-requests',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: 'configuration',
        loadChildren: () => import('./pages/configuration/configuration.module').then(m => m.ConfigurationModule),
        canActivate: [PermissionGuard],
        data: {
          roles: [DataEnums.UserRole.SuperAdmin]
        }
      },
      {
        path: 'qr',
        loadChildren: () => import('./pages/qr/qr.module').then(m => m.QrModule),
        canActivate: [PermissionGuard],
        data: {
          roles: [DataEnums.UserRole.SuperAdmin]
        }
      },
      {
        path: 'group',
        loadChildren: () => import('./pages/group-management/group-management.module').then(m => m.GroupManagementModule),
        canActivate: [PermissionGuard],
        data: {
          roles: [DataEnums.UserRole.TeamAdmin, DataEnums.UserRole.SuperAdmin],
        },
      },
      {
        path: 'admin-users',
        loadChildren: () => import('./pages/admin-users/admin-users.module').then(m => m.AdminUsersModule),
        canActivate: [PermissionGuard],
        data: {
          roles: [DataEnums.UserRole.SuperAdmin],
        },
      },
      {
        path: 'alias',
        loadChildren: () => import('./pages/alias/alias.module').then(m => m.AliasModule),
      },
      {
        path: 'ticket/:id',
        loadChildren: () => import('./pages/ticket/ticket.module').then(m => m.TicketModule),
        canDeactivate: [FormChangesGuard],
      },
      {
        path: 'group/:id/:name',
        loadChildren: () => import('./pages/group/group.module').then(m => m.GroupModule),
        canActivate: [PermissionGuard],
        data: {
          roles: [DataEnums.UserRole.TeamAdmin, DataEnums.UserRole.SuperAdmin],
        },
      },
      {
        path: 'access-denied',
        loadChildren: () => import('./pages/error/error.module').then(m => m.ErrorModule),
      },
      {
        path: 'error',
        loadChildren: () => import('./pages/error/error.module').then(m => m.ErrorModule),
      },
      {
        path: 'not-found',
        loadChildren: () => import('./pages/error/error.module').then(m => m.ErrorModule),
      },
      {
        path: '**',
        redirectTo: 'not-found',
        loadChildren: () => import('./pages/error/error.module').then(m => m.ErrorModule),
      },
    ],
  },
];
