import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { IIdentityUser } from '@models/data.models';

export interface AccountState {
  token: string;
  graphToken: string;
  user: IIdentityUser;
  error: boolean;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'account' })
export class AccountStore extends Store<AccountState> {
  constructor() {
    super({
      token: '',
      graphToken: '',
      user: null,
      error: false,
    });
  }
}
