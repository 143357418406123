import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonModalContainerComponent } from '@shared/components/common-modal-container/common-modal-container.component';

@NgModule({
  declarations: [CommonModalContainerComponent],
  imports: [
    CommonModule,
  ],
  exports: [
    CommonModalContainerComponent,
  ],
})
export class CommonModalContainerModule {
}
