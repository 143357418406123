import { APP_INITIALIZER } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AccountService } from '@core/store/account/account.service';
import { catchError, finalize, tap } from 'rxjs/operators';
import { AuthService } from '@core/services/auth.service';
import { StorageService } from '@core/services/storage.service';
import { Router } from '@angular/router';
import { EMPTY } from 'rxjs';
import { CookieService } from '@core/services/cookie.service';

export const appInitializer =
  (
    authService: AuthService,
    storageService: StorageService,
    accountService: AccountService,
    http: HttpClient,
    router: Router,
  ): (() => Promise<boolean>) =>
    (): Promise<boolean> =>
      new Promise((resolve) => {
        authService.authorizeUser().then(isTokenAcquired => {
          if (!isTokenAcquired) {
            authService.login();
            return;
          } else {
            const savedPathname = storageService.get<string>('redirectPathname');
            if (savedPathname) {
              storageService.remove('redirectPathname');
              window.location.assign(savedPathname);
            }

            http.get('/user/current').pipe(
              tap(res => {
                accountService.setUser(res);
                resolve(true);
              }),
              catchError(err => {
                resolve(true);
                router.navigateByUrl('/error');
                return  EMPTY;
              }),
            ).subscribe()
          }
        })
          .catch(e => {
            router.navigateByUrl('/error');
            resolve(true);
          }).finally(() => {
          // console.log(authService.userInfo);
        })
      });

export const appInitializerProvider = {
  provide: APP_INITIALIZER,
  useFactory: appInitializer,
  multi: true,
  deps: [AuthService, StorageService, AccountService, HttpClient, Router],
};
