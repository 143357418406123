import { Injectable, Provider } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AliasQuery } from '@core/store/alias/alias.query';

@Injectable({ providedIn: 'root' })
export class AliasInterceptor implements HttpInterceptor {
  constructor(private aliasQuery: AliasQuery) {
  }

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const { current } = this.aliasQuery.getValue();

    if (!current) {
      return next.handle(req);
    }

    const headers = req.headers.set('X-AUTH-USERALIAS', current);
    return next.handle(req.clone({ headers }));
  }
}

export const aliasInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AliasInterceptor,
  multi: true,
};
