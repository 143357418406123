<div class="dds-modal-overlay" (click)="close()"></div>

<div ddsResize [ngClass]="{ 'dds-modal_lg': size === 'lg' }" class="dds-modal {{ customClass }}" [class.mobileFull]="mobileFullHeight">
  <div class="dds-modal__header" [class.no-border]="!borders">
    <span class="dds-modal__title">{{ title }}</span>
    <button class="dds-modal__close dds-icon dds-icon_close" type="button" (click)="close()"></button>
  </div>

  <div class="dds-modal__body">
    <ng-content select="[body]"></ng-content>
  </div>

  <div class="dds-modal__footer" [class.no-border]="!borders">
    <ng-content select="[footer]"></ng-content>
  </div>
</div>
