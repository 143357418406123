// .Net generated code


// AreaType
export enum AreaType {
  Room = 1,
  Breakout = 2,
  Washroom = 3
}

// ColumnFilterType
export enum ColumnFilterType {
  None = 0,
  General = 1,
  Tag = 2,
  DateTimeRange = 3,
  Text = 4,
  IntArray = 5,
  IntNullable = 6
}

// TicketStatus
export enum TicketStatus {
  Requested = 1,
  InProgress = 2,
  Resolved = 3,
  Canceled = 4
}

// UserRole
export enum UserRole {
  GeneralPublic = 0,
  SuperVisor = 1,
  TeamAdmin = 10,
  SuperAdmin = 99
}

