import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { AccountState, AccountStore } from './account.store';
import { filter, map } from 'rxjs/operators';
import { UserRole } from '@models/data.enums';

@Injectable({ providedIn: 'root' })
export class AccountQuery extends Query<AccountState> {
  public token = this.select('token');
  public user = this.select('user').pipe(
    filter(data => !!data),
  );
  public isEndUser = this.select('user').pipe(
    filter(data => !!data),
    map(data => data.userRelation.userRole === UserRole.GeneralPublic)
  );
  public isSuperVisor = this.select('user').pipe(
    filter(data => !!data),
    map(data => data.userRelation.userRole === UserRole.SuperVisor)
  );
  public isSuperAdmin = this.select('user').pipe(
    filter(data => !!data),
    map(data => data.userRelation.userRole === UserRole.SuperAdmin),
  );
  public isGroupManagement = this.select('user').pipe(
    filter(data => !!data),
    map(data => data.userRelation.userRole === UserRole.SuperAdmin || data.userRelation.userRole === UserRole.TeamAdmin),
  );
  public isResponsiblePerson = this.select('user').pipe(
    filter(data => !!data),
    map(data => data.userRelation.userRole === UserRole.TeamAdmin || data.userRelation.userRole === UserRole.SuperVisor),
  );
  public isTeamAdminUser = this.select('user').pipe(
    filter(data => !!data),
    map(data => data.userRelation.userRole === UserRole.TeamAdmin),
  );
  public isHaveDefaultFilter = this.select('user').pipe(
    filter(user => !!user),
    map((data) => [UserRole.TeamAdmin, UserRole.SuperVisor, UserRole.SuperAdmin].includes(data.userRelation.userRole))
  );
  public initials = this.user.pipe(
    filter(user => !!user),
    map(user => `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`),
  );
  public responsibleTeamName = this.select('user').pipe(
    filter(data => !!data),
    map(data => data.userRelation.responsibleTeamName),
  );
  public userEmail = this.select('user').pipe(
    filter(user => !!user),
    map(user => user.email),
  );
  public error = this.select('error');
  public graphToken = this.select('graphToken');
  public isLoggedIn = this.token.pipe(map((token) => !!token));

  constructor(store: AccountStore) {
    super(store);
  }
}
