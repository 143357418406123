import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormChangesService {
  public dirty = new BehaviorSubject<boolean>(false);

  public setDirty(state: boolean): void {
    this.dirty.next(state);
  }
}
