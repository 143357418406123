import { EMPTY, merge, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core';

export class SelectSimplifier<C> {
  private readonly context: C;
  private readonly cd: ChangeDetectorRef;
  private obsCollection: Observable<unknown>[] = [];

  constructor(context: C, cd: ChangeDetectorRef) {
    this.context = context;
    this.cd = cd;
  }

  public selectKey<K extends keyof C>(selector: Observable<C[K]>, key: K): SelectSimplifier<C> {
    if (!this.context) {
      throw new Error('Provide context');
    }
    return this.select<C[K]>(selector, (value: C[K]) => this.context[key] = value);
  }

  public watchUntil(destroyed: <U>(source: Observable<U>) => Observable<U>): Observable<unknown> {
    if (!this.obsCollection.length) {
      return EMPTY;
    }

    return merge(...this.obsCollection)
      .pipe(
        destroyed,
        tap(() => this.cd.markForCheck())
      );
  }

  public addObservable(obs: Observable<unknown>): SelectSimplifier<C> {
    this.obsCollection.push(obs);
    return this;
  }

  private select<T>(selector: Observable<T>, cb: (value: T) => void): SelectSimplifier<C> {
    const obs = selector.pipe(tap(cb));

    return this.addObservable(obs);
  }
}
