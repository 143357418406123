// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: {
  production: boolean;
  productionEnv: boolean;
  apiBaseUrl: string;
  enableAlias: boolean;
  clientId: string;
  tenantId: string;
  cacheLocation: string;
  baseUrl: string;
  analyticsScript: string;
  // @ts-ignore
} = window['__env__'] || {};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
