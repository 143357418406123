import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppHeaderComponent } from './app-header.component';
import { RouterModule } from '@angular/router';
import { HeaderModule } from '@usitsdasdesign/dds-ng/header';
import { ButtonModule } from '@usitsdasdesign/dds-ng/button';
import { ProfileModule } from '@usitsdasdesign/dds-ng/profile';
import { ContextMenuModule } from '@usitsdasdesign/dds-ng/context-menu';
import { DropdownModule } from '@usitsdasdesign/dds-ng/dropdown';
import { ButtonActivePipe } from './pipes/button-active.pipe';

@NgModule({
  declarations: [
    AppHeaderComponent,
    ButtonActivePipe
  ],
    imports: [
        CommonModule,
        RouterModule,
        HeaderModule,
        ButtonModule,
        ProfileModule,
        ContextMenuModule,
        DropdownModule,
    ],
  exports: [
    AppHeaderComponent
  ]
})
export class AppHeaderModule {
}
