import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { authInterceptorProvider } from '@core/interceptors/auth.interceptor';
import { httpErrorInterceptorProvider } from '@core/interceptors/http-error.interceptor';
import { appInitializerProvider } from '@core/initializers/app.initializer';
import { LibModule } from '@usitsdasdesign/dds-ng';
import { AppHeaderModule } from '@core/components/app-header/app-header.module';
import { RouterModule } from '@angular/router';
import { appRoutes } from './app.routes';
import { aliasInterceptorProvider } from '@core/interceptors/alias.interceptor';
import { apiUrlInterceptorProvider } from '@core/interceptors/api-url.interceptor';
import { ConfirmationModule } from '@shared/components/modals/confirmation/confirmation.module';
import { analyticsInitializerProvider } from '@core/initializers/analytics.initializer';
import { LogisticTypeSelectComponent } from './shared/components/logistic-type-select/logistic-type-select.component';
import { LogisticAreaSelectComponent } from './shared/components/logistic-area-select/logistic-area-select.component';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    LibModule,
    AppHeaderModule,
    ConfirmationModule,
    RouterModule.forRoot(appRoutes)
  ],
  providers: [
    authInterceptorProvider,
    httpErrorInterceptorProvider,
    appInitializerProvider,
    aliasInterceptorProvider,
    apiUrlInterceptorProvider,
    analyticsInitializerProvider
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
