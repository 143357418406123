import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { SelectSimplifier } from '@shared/utils/select-simplifier.util';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AccountQuery } from '@core/store/account/account.query';
import { IIdentityUser } from '@models/data.models';
import { AnalyticsService } from '@core/services/analytics.service';
import { CookieService } from '@core/services/cookie.service';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public user: IIdentityUser;

  constructor(
    private router: Router,
    private cd: ChangeDetectorRef,
    private accountQuery: AccountQuery,
    private analyticsService: AnalyticsService,
    private cookieService: CookieService
  ) {
  }

  public ngOnInit(): void {
    new SelectSimplifier(this, this.cd)
      .selectKey(this.accountQuery.user, 'user')
      .watchUntil(untilDestroyed(this))
      .subscribe();


    this.watchRouterChanges().subscribe();
  }

  private watchRouterChanges(): Observable<unknown> {
    return this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      tap(event => {
        // @ts-ignore
        this.analyticsService.trackPageInfo(event.urlAfterRedirects as string, this.user?.displayName);
        // this.cookieService.reloadOTBanner();
      }),
    );
  }
}
